<template>
  <div>
    <v-card class="mt-8" flat v-if="credentials_site && editSiteMeta == false">
      <v-card-title>
        <span>{{ credentials_site.name }}</span>
        <v-spacer></v-spacer>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="accent"
              icon
              text
              v-bind="attrs"
              v-on="on"
              @click="editSiteMeta = true"
            >
              <v-icon>mdi-pencil-box</v-icon>
            </v-btn>
          </template>
          <span>Edit details</span></v-tooltip
        >
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="mr-16">
            <p>
              <span class="caption">Created</span><br />{{
                formatDate(credentials_site.created_at)
              }}
            </p>
            <p>
              <span class="caption">Last Updated</span><br />
              {{ formatDate(credentials_site.updated_at) }}
            </p>
            <p>
              <v-switch
                v-model="credentials_site.enabled"
                :label="credentials_site.enabled ? 'Enabled' : 'Disabled'"
                disabled
              ></v-switch>
            </p>
          </v-col>
          <v-col class="mr-16">
            <p>
              <span class="caption">Language</span><br />{{
                credentials_site.language
              }}
            </p>
            <p>
              <span class="caption">Description</span><br />{{
                credentials_site.description
              }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-8" flat v-if="credentials_site && editSiteMeta == true">
      <v-form>
        <v-card-title>
          <span>{{ credentials_site.name }}</span>
          <v-spacer></v-spacer>
          <v-btn color="accent" icon text @click="updateSiteSubmit">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="mr-16">
              <p>
                <span class="caption">Created</span><br />{{
                  formatDate(credentials_site.created_at)
                }}
              </p>
              <p>
                <span class="caption">Last Updated</span><br />
                {{ formatDate(credentials_site.updated_at) }}
              </p>
              <p>
                <v-switch
                  v-model="credentials_site.enabled"
                  :label="credentials_site.enabled ? 'Enabled' : 'Disabled'"
                ></v-switch>
              </p>
            </v-col>
            <v-col class="mr-16"
              ><span class="caption">Language</span><br />
              <v-select
                label="Language"
                placeholder="Language"
                name="Language"
                v-model="credentials_site.language"
                :items="siteNameLanguages"
              />
              <span class="caption">Description</span><br />
              <v-textarea
                label="Description"
                placeholder="Description"
                name="Description"
                v-model="credentials_site.description"
                filled
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
    <v-dialog
      v-model="addCredsDialog"
      max-width="300"
      min-height="200"
      persistent
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      style="z-index: 202; background: #153059"
    >
      <v-card
        style="background: #153059; padding: 0em 1em 1em 0em"
        max-width="300"
        min-height="200"
      >
        <v-card-title class="text-white pt-6">Credentials</v-card-title>
        <v-icon class="float-right mt-n8" @click="addCredsDialog = false"
          >mdi-close</v-icon
        ><v-card-text>
          <v-text-field
            v-model="username"
            placeholder="Username"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-switch
            v-model="credEnabled"
            :label="credEnabled ? 'Enabled' : 'Disabled'"
          ></v-switch>
        </v-card-text>
        <v-card-text style="color: #fff; height: 60px">
          {{ msgContent }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="accent"
            style="min-width: 150px"
            @click="submitCreds()"
            v-if="username.length > 0 && password.length > 0"
            >Submit</v-btn
          >
          <v-btn
            color="rgb(88, 171, 231)"
            depressed
            disabled
            v-else
            style="border-radius: 5px !important; min-width: 150px"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addQuestionDialog"
      max-width="300"
      min-height="200"
      persistent
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      style="z-index: 202; background: #153059"
    >
      <v-card
        style="background: #153059; padding: 0em 1em 1em 0em"
        max-width="300"
        min-height="200"
      >
        <v-card-title class="text-white pt-6"
          >Add Security Question</v-card-title
        >
        <v-icon class="float-right mt-n8" @click="addQuestionDialog = false"
          >mdi-close</v-icon
        ><v-card-text>
          <v-text-field
            v-model="question"
            placeholder="Question"
          ></v-text-field>

          <v-text-field v-model="answer" placeholder="Answer"></v-text-field>
        </v-card-text>
        <v-card-text style="color: #fff; height: 60px">
          {{ msgContent }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="accent"
            style="min-width: 150px"
            @click="submitQuestions()"
            v-if="question.length > 0 && answer.length > 0"
            >Submit</v-btn
          >
          <v-btn
            color="rgb(88, 171, 231)"
            depressed
            disabled
            v-else
            style="border-radius: 5px !important; min-width: 150px"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteQuestionDialog"
      max-width="300"
      min-height="200"
      persistent
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      style="z-index: 202; background: #153059"
    >
      <v-card
        style="background: #153059; padding: 0em 1em 1em 0em"
        max-width="300"
        min-height="200"
      >
        <v-card-title class="text-white pt-6">Confirmation</v-card-title>
        <v-icon class="float-right mt-n8" @click="deleteQuestionDialog = false"
          >mdi-close</v-icon
        >
        <v-card-text style="color: #fff"
          >Are you sure you want to delete the security question "{{
            deleteSecurityText
          }}"?</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="accent"
            style="min-width: 150px"
            @click="deleteQuestionDialogSubmit"
            >SUBMIT</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="updateCreds"
      max-width="300"
      min-height="200"
      persistent
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      style="z-index: 202; background: #153059"
    >
      <v-card
        style="background: #153059; padding: 0em 1em 1em 0em"
        max-width="300"
        min-height="200"
      >
        <v-card-title class="text-white pt-6">Confirmation</v-card-title>
        <v-icon class="float-right mt-n8" @click="updateCredsSubmitClose()"
          >mdi-close</v-icon
        >
        <v-card-text style="color: #fff"
          >Are you sure you want to {{ updateCredsText }} these
          credentials?</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="accent"
            style="min-width: 150px"
            @click="updateCredsSubmit()"
            >SUBMIT</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addUrlDialog"
      max-width="300"
      min-height="200"
      persistent
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      style="z-index: 202; background: #153059"
    >
      <v-card
        style="background: #153059; padding: 0em 1em 1em 0em"
        max-width="300"
        min-height="200"
      >
        <v-card-title class="text-white pt-6">Urls</v-card-title>
        <v-icon class="float-right mt-n8" @click="addUrlDialog = false"
          >mdi-close</v-icon
        ><v-card-text>
          <v-text-field
            v-model="new_url"
            placeholder="URL"
            label="URL"
          ></v-text-field>
        </v-card-text>
        <v-card-text style="color: #fff; height: 60px">
          {{ msgContentUrl }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="accent"
            style="min-width: 150px"
            @click="submitUrl()"
            v-if="new_url.length > 0"
            >Submit</v-btn
          >
          <v-btn
            color="rgb(88, 171, 231)"
            depressed
            disabled
            v-else
            style="border-radius: 5px !important; min-width: 150px"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="DarkReconSiteManagerCredentials">
      <h2 class="font-weight-thin mt-10 ml-2">Credentials</h2>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="#E86051"
            class="mt-n8 float-right mr-4"
            icon
            text
            @click="addCreds()"
          >
            <v-icon>mdi-plus-circle</v-icon></v-btn
          >
        </template>
        <span>Add credential</span></v-tooltip
      >

      <template>
        <v-expansion-panels focusable class="mt-6">
          <v-expansion-panel v-for="(item, i) in credentials" :key="i">
            <v-expansion-panel-header disable-icon-rotate>
              {{ item.username }}
              <template v-slot:actions>
                <v-switch
                  @click.stop="updateItem(item)"
                  v-model="item.enabled"
                  :label="item.enabled ? 'Enabled' : 'Disabled'"
                ></v-switch>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <h2 class="font-weight-light mb-4 mt-4">Credentials</h2>
              <v-row>
                <v-col class="mr-16">
                  <p>
                    <span class="caption">Username</span><br />{{
                      item.username
                    }}
                  </p>
                </v-col>
                <v-col class="mr-16">
                  <p v-if="editPassword == false">
                    <span class="caption"> Password</span><br />
                    <span v-if="!shownPasswords.includes(item.id)"
                      >*************</span
                    >&nbsp;&nbsp;
                    <span v-if="shownPasswords.includes(item.id)"
                      >{{ item.password }} </span
                    >&nbsp;&nbsp;

                    <v-icon
                      v-if="!shownPasswords.includes(item.id)"
                      @click="showPassword(item.id)"
                      style="cursor: pointer"
                      color="primary"
                      class="mt-n1"
                      >mdi-eye
                    </v-icon>
                    <v-icon
                      v-if="shownPasswords.includes(item.id)"
                      @click="hidePassword(item.id)"
                      style="cursor: pointer"
                      color="primary"
                      class="mt-n1"
                      >mdi-eye-off
                    </v-icon>
                  </p>

                  <p v-if="editPassword == true">
                    <v-text-field
                      v-model="item.password"
                      :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Password"
                      @click:append="show1 = !show1"
                      style="max-width: 250px"
                    ></v-text-field>
                  </p>
                </v-col>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="accent"
                      text
                      @click="copyText(item.password)"
                      class="mt-6 mr-0"
                      ><v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy Password</span>
                </v-tooltip>
                <v-tooltip right v-if="editPassword == false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mt-6 mr-3"
                      color="accent"
                      icon
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="editPassword = true"
                    >
                      <v-icon>mdi-pencil-box</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Password</span></v-tooltip
                >
                <v-tooltip right v-if="editPassword == true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mt-6 mr-3"
                      color="accent"
                      icon
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="savePassword(item)"
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Save Password</span></v-tooltip
                >
              </v-row>
              <v-row>
                <v-col class="mr-16 mt-n6">
                  <p>
                    <span class="caption">Banned</span><br />

                    <span v-if="item.banned">Yes</span>
                    <span v-if="!item.banned">No</span>
                  </p>
                </v-col>
                <v-col> &nbsp; </v-col>
              </v-row>
              <hr />
              <h2 class="font-weight-light mb-4 mt-4">Security Questions</h2>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="#E86051"
                    class="mt-n12 float-right"
                    icon
                    text
                    @click="addQuestion(item)"
                  >
                    <v-icon>mdi-plus-circle</v-icon></v-btn
                  >
                </template>
                <span>Add Security Question</span></v-tooltip
              >
              <div
                v-for="(question, q) in credentialSecurityQuestions"
                :key="q"
                style="display: flex"
              >
                <v-row v-if="question.credentials_id == item.id">
                  <v-col class="mr-0">
                    <p>
                      <span class="caption">Question</span><br />{{
                        question.question
                      }}
                    </p>
                  </v-col>
                  <v-col class="mr-0">
                    <p>
                      <span class="caption">Answer</span><br />{{
                        question.answer
                      }}
                    </p>
                  </v-col>

                  <v-col class="mr-0">
                    <p
                      style="cursor: pointer"
                      @click="() => deleteQuestionDialogOpen(question)"
                    >
                      <v-icon color="accent" class="mr-2 mt-6"
                        >mdi-delete
                      </v-icon>
                    </p>
                  </v-col>
                </v-row>
              </div>

              <hr />

              <v-row v-if="credentials_site" class="mt-4">
                <v-col class="mr-16">
                  <p>
                    <span class="caption">Date Created</span><br />{{
                      formatDate(credentials_site.created_at)
                    }}
                  </p>
                </v-col>
                <v-col class="mr-16">
                  <p>
                    <span class="caption">Site ID</span><br />{{
                      credentials_site.id
                    }}
                  </p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <h2 class="font-weight-thin mt-10 ml-2">URLs</h2>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="#E86051"
            class="mt-n8 float-right mr-4"
            icon
            text
            @click="addUrl()"
          >
            <v-icon>mdi-plus-circle</v-icon></v-btn
          >
        </template>
        <span>Add URL</span></v-tooltip
      >
      <v-data-table
        :headers="url_headers"
        :items="site_urls"
        :items-per-page="-1"
        disable-sort
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr
            :id="item.id"
            @mouseenter="changeIconColors('enter', item.id)"
            @mouseleave="changeIconColors('exit', item.id)"
          >
            <td>{{ item.url }}</td>
            <td>{{ formatDate(item.created) }}</td>
            <td style="cursor: pointer" @click="() => deleteUrl(item)">
              <v-icon color="primary" class="mr-2">mdi-delete </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <v-toolbar flat class="mt-8">
      <v-toolbar-title class="text-h1"
        >Forums ({{ this.totalForums }})</v-toolbar-title
      >
      <v-spacer />
      <v-menu offset-y v-if="this.selectedForums.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="accent" dark depressed v-bind="attrs" v-on="on" small>
            <v-icon left> mdi-menu-down </v-icon> Actions
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in ['Enable', 'Disable']"
            :key="index"
          >
            <v-list-item-title
              @click="updateForums(item)"
              style="cursor: pointer"
              >{{ item }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <v-btn color="accent" depressed small @click="goToForumManager"
        >VIEW ALL</v-btn
      >
    </v-toolbar>
    <v-data-table
      class="forumTable"
      :headers="forum_headers"
      :items="site_forums"
      :items-per-page="10"
      disable-sort
      v-model="selectedForums"
      show-select
    >
      <template v-slot:item.enabled="{ value }">
        <v-icon color="primary" class="mr-2" v-if="value"
          >mdi-check-circle
        </v-icon>
        <v-icon color="#112748" medium class="mr-2" v-if="!value"
          >mdi-checkbox-blank-circle
        </v-icon>
      </template>
    </v-data-table>

    <v-card class="my-10" flat>
      <v-card-title>
        <span>Status</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-timeline align-top dense class="status-timeline">
          <v-timeline-item
            v-for="(item, index) of site_statuses"
            :key="index"
            icon="mdi-check-bold"
            fill-dot
            small
          >
            <v-row dense>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12">
                    <p class="text-subtitle-1 mb-0">{{ item.status }}</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <p class="ml-5 mb-0 text-caption faded-text">
                      {{ formatDate(item.created_at) }}
                    </p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1"> </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <p class="ml-5 mb-0 text-body-2">
                      {{ item.note }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item
            v-if="currentStatus.status == 'INVALID'"
            color="secondary"
            flat
            small
            ><span class="faded-button"
              >No actions available</span
            ></v-timeline-item
          >
          <v-timeline-item color="secondary" flat small v-else>
            I want to change the status
            <div style="width: 200px">
              <v-select
                placeholder="Select status"
                v-model="selected"
                :items="formattedStatus"
              >
              </v-select>
            </div>
            <div style="width: 450px">
              <v-textarea
                filled
                flat
                v-model="statusNote"
                placeholder="A note is required for submission"
              ></v-textarea>
            </div>
            <v-btn
              color="primary"
              v-if="selected && statusNote"
              @click="submitNewStatus()"
              >Submit</v-btn
            >
            <v-btn color="primary" disabled v-if="!selected || !statusNote"
              >Submit</v-btn
            >
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="showClipboard"
      color="#58abe7"
      centered
      :timeout="2000"
      elevation="24"
    >
      {{ showMsg }}
    </v-snackbar>
    <v-dialog
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      v-model="openModal"
      max-width="400"
    >
      <v-card width="400" class="pr-8 pl-8 pt-4 pb-4" min-height="200">
        <v-icon class="float-right mt-3 mr-n5" @click="openModal = false"
          >mdi-close</v-icon
        >
        <v-card-title>
          <h4 class="white--text ml-n10 mb-5" style="font-weight: 100">
            Bulk {{ this.selectedAction.replace(" Selected", "") }}
          </h4>
        </v-card-title>
        <v-card-text
          >Are you sure you want to
          {{ this.selectedAction.replace(" Selected", "").toLowerCase() }} the
          selected forums? This will activate routine data
          scraping.</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="updateForumsSubmit('No')"
            >Cancel</v-btn
          >
          <v-btn text color="accent" @click="updateForumsSubmit('Yes')">
            {{ this.selectedAction.replace(" Selected", "") }}
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>
<script>
import store from "@/store";
import { formatDate } from "@/utils/common";
export default {
  name: "DarkReconSiteManagerCredentials",
  data: function () {
    return {
      editPassword: false,
      addQuestionDialog: false,
      deleteQuestionDialog: false,
      deleteSecurityText: "",
      answer: "",
      question: "",
      selectedAction: "Enable Selected",
      openModal: false,
      selectedForums: [],
      addUrlDialog: false,
      showClipboard: false,
      showMsg: "",
      editSiteMeta: false,
      formatDate: formatDate,
      msgContent: "",
      msgContentUrl: "",
      new_url: "",
      show1: false,
      addCredsDialog: false,
      username: "",
      password: "",
      selectedCredential: null,
      selectedQuestion: null,
      credEnabled: false,
      formattedStatus: [],
      updateCredsText: "",
      updateCreds: false,
      site_id: null,
      siteNameLanguages: [
        "English",
        "Russian",
        "Danish",
        "German",
        "French",
        "Arabic",
        "Swedish",
        "Dutch",
      ],
      shownPasswords: [],
      headers: [
        {
          text: "Username",
          value: "username",
          filterable: false,
        },
        {
          text: "Password",
          value: "password",
          filterable: false,
        },
        {
          text: "Enabled",
          value: "enabled",
          filterable: false,
        },
      ],
      url_headers: [
        {
          text: "URL",
          value: "url",
          filterable: false,
        },
        {
          text: "Created",
          value: "created_at",
          filterable: false,
        },
        {
          text: "",
          value: "delete",
          filterable: false,
        },
      ],
      forum_headers: [
        {
          text: "Title",
          value: "title",
          filterable: false,
        },
        {
          text: "Enabled",
          value: "enabled",
          filterable: false,
        },
        {
          text: "Date Added",
          value: "created_at",
          filterable: false,
        },
        {
          text: "Last Activity Date",
          value: "last_activity_date",
          filterable: false,
        },
        {
          text: "Last Scraped Date",
          value: "last_scraped_date",
          filterable: false,
        },
      ],
      selected: null,
      statusNote: null,
      statusFilters: [
        "NEW",
        "UNSCRAPABLE",
        "VALID",
        "NEEDS_CREDENTIALS",
        "CONFIG_IN_PROGRESS",
        "TESTING",
        "LIVE",
        "BROKEN",
        "INVALID",
      ],
    };
  },
  mounted() {
    this.site_id = this.$route.params.site_id;
    console.log(this.site_id, this.$route.params.site_id);
    store.commit("darkReconSiteManager/replaceValue", {
      key: "credentials_site_id",
      value: this.site_id,
    });
    this.getCreds();
    store.dispatch("darkReconSiteManager/getForums");
  },
  methods: {
    copyText(text) {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showMsg = "Copied!";
      this.showClipboard = true;
    },
    savePassword(item) {
      store
        .dispatch("darkReconSiteManager/updateCredsPassword", item)
        .then((result) => {
          console.log(result);
          this.getCreds();
          this.editPassword = false;
        });
    },
    deleteQuestionDialogOpen(question) {
      this.selectedQuestion = question;
      this.deleteSecurityText = question.question;
      this.deleteQuestionDialog = true;
    },
    deleteQuestionDialogSubmit() {
      this.deleteQuestion();
    },
    deleteQuestion() {
      store
        .dispatch("darkReconSiteManager/deleteQuestion", this.selectedQuestion)
        .then((result) => {
          console.log(result);
          this.showMsg = result.message;
          this.showClipboard = true;
          this.deleteQuestionDialog = false;
        });
    },
    updateForums(value) {
      console.log(value, this.selectedForums);
      this.selectedAction = value;
      this.openModal = true;
    },
    updateForumsSubmit(val) {
      if (val == "Yes") {
        const forumData = {
          value: this.selectedAction,
          forums: this.selectedForums,
        };
        console.log(this.selectedAction);
        store
          .dispatch("darkReconSiteManager/updateSiteForums", forumData)
          .then((result) => {
            console.log(result);
            this.openModal = false;
            this.showMsg = result.message;
            this.showClipboard = true;
            store.dispatch("darkReconSiteManager/getForums");
          });
      } else {
        this.openModal = false;
        return;
      }
    },
    deleteUrl(item) {
      store.dispatch("darkReconSiteManager/deleteUrl", item).then((result) => {
        console.log(result);
        this.showMsg = result.message;
        this.showClipboard = true;
      });
    },
    addUrl() {
      this.addUrlDialog = true;
    },
    submitUrl() {
      store
        .dispatch("darkReconSiteManager/addUrl", this.new_url)
        .then((result) => {
          console.log(result);
          if (result.created == true) {
            this.msgContentUrl = "URL successfully added: " + result.url;
          } else {
            this.msgContentUrl = "URL could not be added: " + result.url;
          }
          setTimeout(() => {
            this.addUrlDialog = false;
            this.msgContentUrl = "";
            this.new_url = "";
          }, 500);
        });
    },
    addCreds() {
      this.addCredsDialog = true;
    },
    addQuestion(item) {
      this.selectedCredential = item;
      this.addQuestionDialog = true;
    },

    submitNewStatus(val) {
      const newStatus = this.selected.replaceAll(" ", "_");
      const payload = { status: newStatus, note: this.statusNote };
      store
        .dispatch("darkReconSiteManager/addStatus", payload)
        .then((result) => {
          console.log(result);

          this.showMsg = result;
          this.showClipboard = true;
          this.statusNote = "";
        });
    },
    submitQuestions() {
      const payload = {
        question: this.question,
        answer: this.answer,
        site_id: this.site_id,
        credential_id: this.selectedCredential.id,
      };

      store
        .dispatch("darkReconSiteManager/addNewSecurityQuestions", payload)
        .then((result) => {
          console.log(result);
          if (result.success == true) {
            this.msgContent = "Security Question added successfully";
            setTimeout(() => {
              this.getCreds();
              this.addQuestionDialog = false;
              this.msgContent = "";
              this.answer = "";
              this.question = "";
            }, 500);
          } else {
            this.msgContent = result.msg;
          }
        });
    },
    submitCreds() {
      const payload = {
        username: this.username,
        password: this.password,
        enabled: this.credEnabled,
        site_id: this.site_id,
      };
      store
        .dispatch("darkReconSiteManager/addNewCreds", payload)
        .then((result) => {
          console.log(result);
          if (result.create.created == true) {
            this.msgContent = "Credential created successfully";
            setTimeout(() => {
              this.getCreds();
              this.addCredsDialog = false;
              this.msgContent = "";
              this.username = "";
              this.password = "";
              this.credEnabled = false;
            }, 500);
          } else {
            this.msgContent = result.msg;
          }
        });
    },
    getCreds() {
      store.dispatch("darkReconSiteManager/getCredentials").then((result) => {
        console.log(result);
      });
    },
    updateSiteSubmit() {
      store
        .dispatch(
          "darkReconSiteManager/updateSiteContent",
          this.credentials_site
        )
        .then((result) => {
          console.log(result);
          this.showMsg = result.message;
          this.showClipboard = true;
          this.editSiteMeta = false;
          this.getCreds();
        });
    },
    updateItem(item) {
      console.log(item);
      store.commit("darkReconSiteManager/replaceValue", {
        key: "selectedCreds",
        value: item,
      });
      if (item.disabled == true) {
        item.disabled = false;
        this.updateCredsText = "enable";
      } else {
        item.disabled = true;
        this.updateCredsText = "disable";
      }
      this.updateCreds = true;
    },
    updateCredsSubmit() {
      store.dispatch("darkReconSiteManager/updateCreds").then((result) => {
        console.log(result);
        this.updateCreds = false;
        this.getCreds();
      });
    },
    updateCredsSubmitClose() {
      this.updateCreds = false;
      this.getCreds();
    },
    goToForumManager() {
      console.log(this.credentials_site.id);
      let routeData = this.$router.resolve({
        path: `/darkrecon/sites/${this.credentials_site.id}/forums`,
      });
      window.open(routeData.href, "_blank");
    },
    showPassword(item_id) {
      this.shownPasswords.push(item_id);
    },
    hidePassword(item_id) {
      const index = this.shownPasswords
        .map((item_id) => {
          return item_id;
        })
        .indexOf(item_id);
      this.shownPasswords.splice(index, 1);
    },
    changeIconColors(hover, id) {
      document.getElementById(id).classList.remove("hovering");

      if (hover == "enter") {
        document.getElementById(id).classList.add("hovering");
      }
      if (hover == "exit") {
        document.getElementById(id).classList.remove("hovering");
      }
    },
  },
  computed: {
    totalForums: () => {
      const site_forums_total =
        store.state.darkReconSiteManager.site_forums_total || 0;
      return site_forums_total;
    },
    site_forums: () => {
      const site_forums = store.state.darkReconSiteManager.site_forums || [];
      for (const forum of site_forums) {
        forum.created_at = formatDate(forum.created_at);
        forum.last_activity_date = formatDate(forum.last_activity_date);
        forum.last_scraped_date = formatDate(forum.last_scraped_date);
        if (forum.disabled == true) {
          forum.enabled = false;
        } else {
          forum.enabled = true;
        }
      }
      return site_forums;
    },
    credentialSecurityQuestions: () => {
      const credentialSecurityQuestions =
        store.state.darkReconSiteManager.credentialSecurityQuestions || [];
      return credentialSecurityQuestions;
    },
    credentials: () => {
      const credentials = store.state.darkReconSiteManager.credentials || [];
      return credentials;
    },
    credentials_site: () => {
      const credentials_site =
        store.state.darkReconSiteManager.credentials_site || null;
      return credentials_site;
    },
    site_statuses: () => {
      const site_statuses =
        store.state.darkReconSiteManager.site_statuses || [];
      return site_statuses;
    },
    site_urls: () => {
      const site_urls = store.state.darkReconSiteManager.site_urls || [];
      return site_urls;
    },
    currentStatus() {
      return this.site_statuses[this.site_statuses.length - 1] || {};
    },
  },
  watch: {
    addCredsDialog: {
      handler: function (val) {
        if (val == false) {
          this.msgContent = "";
          this.username = "";
          this.password = "";
          this.credEnabled = false;
        }
      },
    },
    currentStatus: {
      handler: function (val) {
        console.log(val, this.statusFilters);
        if (val.status == "NEW") {
          this.formattedStatus = this.statusFilters.filter(
            (x) => x == "UNSCRAPABLE" || x == "VALID" || x == "INVALID"
          );
        }
        if (val.status == "UNSCRAPABLE") {
          this.formattedStatus = this.statusFilters.filter(
            (x) => x == "VALID" || x == "INVALID"
          );
        }
        if (val.status == "VALID") {
          this.formattedStatus = this.statusFilters.filter(
            (x) =>
              x == "NEEDS_CREDENTIALS" ||
              x == "CONFIG_IN_PROGRESS" ||
              x == "INVALID"
          );
        }
        if (val.status == "NEEDS_CREDENTIALS") {
          this.formattedStatus = this.statusFilters.filter(
            (x) => x == "CONFIG_IN_PROGRESS" || x == "INVALID"
          );
        }
        if (val.status == "CONFIG_IN_PROGRESS") {
          this.formattedStatus = this.statusFilters.filter(
            (x) => x == "TESTING" || x == "INVALID"
          );
        }
        if (val.status == "TESTING") {
          this.formattedStatus = this.statusFilters.filter(
            (x) => x == "LIVE" || x == "CONFIG_IN_PROGRESS" || x == "INVALID"
          );
        }
        if (val.status == "LIVE") {
          this.formattedStatus = this.statusFilters.filter(
            (x) => x == "BROKEN" || x == "INVALID"
          );
        }

        if (val.status == "BROKEN") {
          this.formattedStatus = this.statusFilters.filter(
            (x) =>
              x == "NEEDS_CREDENTIALS" ||
              x == "CONFIG_IN_PROGRESS" ||
              x == "INVALID"
          );
        }

        const formattedStatus = this.formattedStatus.map((x) => {
          return x.replaceAll("_", " ");
        });
        this.formattedStatus = formattedStatus;
      },
    },
  },
};
</script>
<style scoped>
.hovering .v-icon {
  color: #112748 !important;
}
.forumTable
  >>> table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}
.DarkReconSiteManagerCredentials >>> td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 300px;
  max-width: 300px;
}
.DarkReconSiteManagerCredentials >>> td:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;
  max-width: 100px;
}
.DarkReconSiteManagerCredentials >>> td:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 300px;
  max-width: 300px;
}
</style>
